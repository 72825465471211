<template>
    <v-form ref="form" @submit.prevent="clickSave">
        <v-card v-model="formHasErrors" lazy-validation>
            <v-card-title class="modal__title">
                <span class="text-h6">{{ dialogEdit ? $t('action.edit_section') : $t('action.new_section') }}</span>
                <div class="d-flex align-center">
                    <button type="button" class="btn-close ml-5" @click="clickCancel">
                        <v-icon class="fa-solid fa-xmark"></v-icon>
                    </button>
                </div>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col sm="12">
                            <v-select
                                v-model="typeSection.type"
                                :items="items"
                                :label="$t('form.type')"
                                ref="type"
                                :disabled="dialogEdit"
                                :rules="dialogEdit ? [] : [requiredRule]"
                                required
                            ></v-select>
                        </v-col>
                        <v-col sm="12" v-if="typeSection.type">
                            <v-text-field
                                :label="$t('form.title')"
                                v-model="typeSection.title"
                                ref="title"
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" v-if="typeSection.type === 'Lesson'">
                            <label for="" class="d-block mb-1">説明</label>
                            <MyEditor :data.sync="editorDataLesson.text" />
                        </v-col>
                        <v-col sm="12" v-if="typeSection.type === 'Test'">
                            <v-text-field
                                :label="$t('form.pass_score')"
                                v-model="editorDataTest.pass_score"
                                ref="pass_score"
                                :rules="[minRule({ min: 0 }), maxRule({ max: 100 }), requiredRule]"
                                @keypress="PreventMinusAndCharater"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" v-if="typeSection.type === 'Test'">
                            <v-text-field
                                :label="$t('form.time_limit')"
                                v-model="editorDataTest.time_limit"
                                ref="time_limit"
                                :suffix="$t('cms.minute')"
                                :rules="[minRule({ min: 1 })]"
                                @keypress="PreventMinusAndCharater"
                                type="number"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12" v-if="typeSection.type === 'Form'">
                            <v-checkbox
                                v-model="editorDataForm.retake"
                                :label="$t('cms.can_answer_many_times')"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn type="button" color="blue darken-1" text @click="clickCancel">{{ $t('cancel') }}</v-btn>
                <v-btn type="button" color="blue darken-1" text @click="clickSave">{{ $t('form.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mixinRules } from '../../../mixins/rules';
import MyEditor from '../MyEditor';

export default {
    mixins: [mixinRules],
    components: {
        MyEditor,
    },
    props: {
        cuItem: { type: Object },
        formTitle: { type: String },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
        typeSection: { type: Object },
        editorDataLesson: { type: Object },
        editorDataTest: { type: Object },
        editorDataForm: { type: Object },
        openModalUploadQuestion: { type: Function },
    },
    data() {
        return {
            items: [
                { text: this.$t('cms.lesson'), value: 'Lesson' },
                { text: this.$t('cms.test'), value: 'Test' },
                { text: this.$t('cms.form'), value: 'Form' },
                { text: this.$t('cms.upload_question_as_a_file'), value: 'UPLOAD_QUESTIONS' },
            ],
            itemsConditions: [
                { text: this.$t('Open page'), value: 'SEEN' },
                { text: this.$t('Watch the video to the end'), value: 'WATCHED' },
                { text: this.$t('Answer the quiz correctly'), value: 'QUIZ' },
                { text: this.$t('View slideshows to the end'), value: 'SLIDE' },
            ],
            errorMessages: '',
            formHasErrors: false,
        };
    },
    computed: {
        form() {
            return {
                type: this.typeSection.type,
                title: this.typeSection.title,
            };
        },
    },
    created() {
        this.checkSections(this.cuItem);
    },
    watch: {
        cuItem(val) {
            if (val.lesson || val.test || val.form) this.checkSections(val);
        },
        '$props.typeSection.type': function (value) {
            if (value === 'UPLOAD_QUESTIONS') {
                this.clickCancel();
                this.openModalUploadQuestion();
            }
        },
    },
    methods: {
        validate() {
            this.formHasErrors = false;
            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });
        },

        resetForm() {
            this.errorMessages = [];
            this.formHasErrors = false;
            this.typeSection.type = '';
            this.typeSection.title = '';
            this.editorDataLesson.text = '';
            this.editorDataTest.time_limit = null;
            this.editorDataTest.pass_score = null;
            this.editorDataForm.retake = false;

            Object.keys(this.form).forEach((f) => {
                this.$refs.form.resetValidation();
            });
        },

        clickSave() {
            const isValid = this.$refs.form.validate();
            if (isValid) {
                this.save((isSuccess) => {
                    if (isSuccess) this.clickCancel();
                });
            }
        },

        clickCancel() {
            this.resetForm();
            this.closeCU();
        },

        checkSections(val) {
            if (val.lesson) {
                this.typeSection.type = 'Lesson';
                this.typeSection.title = val.lesson?.title || '';
                this.editorDataLesson.text = val.lesson?.text;
            } else if (val.test) {
                this.typeSection.type = 'Test';
                this.typeSection.title = val.test?.title || '';
                this.editorDataTest.time_limit = val.test?.time_limit?.toString();
                this.editorDataTest.pass_score = parseInt(val.test?.pass_score);
            } else if (val.form) {
                this.typeSection.type = 'Form';
                this.typeSection.title = val.form?.title || '';
                this.editorDataForm.retake = val.form?.retake || false;
            } else {
                this.typeSection.type = '';
                this.typeSection.title = '';
                this.editorDataLesson.text = '';
                this.editorDataTest.time_limit = null;
                this.editorDataTest.pass_score = null;
            }
        },
        PreventMinusAndCharater(e) {
            // remove minus and e charater when type
            if (e.code === 'Minus' || e.code === 'KeyE') {
                e.preventDefault();
            }
        },
    },
};
</script>

<style lang="scss">
.ck.ck-editor {
    width: 100%;
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
    position: absolute !important;
}

.ck.ck-content {
    min-height: 100px;
}

.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;

    .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }

    .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
