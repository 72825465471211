<template>
    <div class="cms-question">
        <a-row :gutter="24" type="flex">
            <a-col :span="24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template>
                        <v-data-table
                            :headers="tableColumns"
                            :items="items"
                            :items-per-page="items.length"
                            :loading="isLoadingQuestion || isLoadingFormQuestion"
                            class="tableQuestion"
                            show-select
                            v-model="selected"
                            hide-default-footer
                            :loading-text="$t('cms.loading')"
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogCU" persistent max-width="1000px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                color="primary"
                                                dark
                                                class="mr-2"
                                                @click="typeCollapse === 'form' ? onAddFormQuestion() : onAddNewItem()"
                                                small
                                            >
                                                <v-icon small> mdi-plus </v-icon>
                                                {{
                                                    (typeCollapse === 'lesson' && $t('cms.add_sub_lesson')) ||
                                                    (typeCollapse === 'test' && $t('cms.add_question_test')) ||
                                                    (typeCollapse === 'form' && $t('cms.add_question_test'))
                                                }}
                                            </v-btn>
                                            <v-btn
                                                color="error"
                                                dark
                                                class="mr-2"
                                                small
                                                v-show="selected.length > 0"
                                                @click="onDeleteMulti"
                                            >
                                                <v-icon small> mdi-delete </v-icon>
                                                &nbsp;{{ $t('form.delete') }}
                                            </v-btn>
                                        </template>

                                        <QuestionModal
                                            :cuItem="cuItemQuestion"
                                            :typeSection="typeSection"
                                            :formTitle="formTitle"
                                            :closeCU="closeCU"
                                            :save="onSaveItem"
                                            :propsField="propsField"
                                            :dialogEdit="dialogEdit"
                                            :experience="experience"
                                            v-if="dialogCU"
                                        >
                                        </QuestionModal>
                                    </v-dialog>

                                    <v-dialog v-model="dialogFormQuestion" max-width="1000px">
                                        <FormQuestionModal
                                            :cuItem="cuItemQuestion"
                                            :onSave="onSaveFormQuestion"
                                            :onClose="onCloseFormQuestion"
                                            :dialogEdit="dialogEdit"
                                        />
                                    </v-dialog>

                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete
                                            :closeDelete="closeDelete"
                                            :deleteItemConfirm="
                                                typeCollapse === 'form'
                                                    ? onConfirmDeleteFormQuestion
                                                    : deleteItemConfirm
                                            "
                                        >
                                        </ModalDelete>
                                    </v-dialog>

                                    <v-dialog v-model="dialogNoti" max-width="500px">
                                        <ModalNoti :close="closeNoti" :message="formMessage"></ModalNoti>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:[`item.title`]="{ item }">
                                <div class="my-2 line-clamp-2 text-break cms-question__problem">
                                    <MyEditor
                                        :data="item.title || item.problem_statement || item.question_sentence"
                                        :isReadOnly="true"
                                    />
                                </div>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.updated_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.updated_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn
                                    color="info"
                                    class="mr-2"
                                    small
                                    @click="typeCollapse === 'form' ? onEditFormQuestion(item) : onUpdateItem(item)"
                                >
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn
                                    color="error"
                                    dark
                                    class="mr-2"
                                    small
                                    @click="typeCollapse === 'form' ? onDeleteFormQuestion(item) : onDeleteItem(item)"
                                >
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                        </v-data-table>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <f-notification-dialog
            class="dialogNoti"
            :isShowDialog="isShowDialogMulti"
            width="500px"
            :title="$t('cms.delete_multiple')"
            :content="$t('cms.confirm_delete_multiple')"
            :actions="actionsMulti"
        />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormQuestionModal from '../../components/CMS/Modal/FormQuestion.vue';
import QuestionModal from '../../components/CMS/Modal/Question.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS//Modal/Noti.vue';
import MyEditor from '../../components/CMS/MyEditor';
import { CRUD } from '../../constants';
import { mixinFormats, mixinToasts, mixinLoadings } from '../../mixins';

export default {
    mixins: [mixinFormats, mixinToasts, mixinLoadings],
    components: { FormQuestionModal, QuestionModal, ModalDelete, ModalNoti, MyEditor },
    props: {
        typeCollapse: { type: String },
        items: { type: Array },
        cuItem: { type: Object },
        getQuestionToSection: { type: Function },
    },
    data() {
        return {
            tableTitle: `Question`,
            tableColumns: [
                { text: this.$t('form.title'), value: 'title', sortable: false, width: '300px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                {
                    text: this.$t('form.updated_at'),
                    value: 'updated_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '210px' },
            ],
            fieldsSearch: [
                {
                    title: this.$t('form.title'),
                    value: 'title',
                },
            ],
            idSection: '',
            experience: {
                type: '',
                title: '',
                text: '',
                time_limit: 0,
                problem_statement: '',
                explanation: '',
                answers: [],
            },
            selectedField: 'title',
            search: '',
            page: 1,
            cuIndex: -1,
            cuItemQuestion: {},
            cuItemDelete: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            dialogFormQuestion: false,
            isShowDialogMulti: false,
            actionsMulti: [],
            selected: [],
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
        };
    },
    computed: {
        ...mapState('question', ['isLoadingQuestion']),
        ...mapState('formQuestion', ['isLoadingFormQuestion']),
        formTitle() {
            return this.cuIndex === -1 ? 'New Item' : 'Edit Item';
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
        typeSection() {
            return this.cuItem.lesson !== null ? 'Lesson' : 'Test';
        },
    },
    created() {
        this.setCurrentItem();
    },
    watch: {
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
        cuItem(val) {
            this.setCurrentItem();
        },
    },
    methods: {
        ...mapActions('lesson', ['postNewLesson', 'putOneLesson', 'deleteOneLesson', 'deleteMultiSubLessons']),
        ...mapActions('test', ['postNewTest', 'putOneTest', 'deleteOneTest', 'deleteMultiSubTests']),
        ...mapActions('formQuestion', ['postFormQuestion', 'putFormQuestion', 'deleteFormQuestion']),

        onAddNewItem() {
            this.dialogEdit = false;
            this.dialogCU = true;
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.items.indexOf(item);
            this.cuItemQuestion = Object.assign({}, item);
        },
        onDeleteItem(item) {
            this.cuIndex = this.items.indexOf(item);
            this.cuItemDelete = Object.assign({}, item);
            this.dialogDelete = true;
        },
        onDeleteMulti() {
            this.isShowDialogMulti = true;
            this.actionsMulti = [
                {
                    title: this.$t('cancel'),
                    function: this.cancelMulti,
                },
                {
                    title: this.$t('yes'),
                    function: this.agreeMulti,
                },
            ];
        },
        async agreeMulti() {
            this.isShowDialogMulti = false;
            this.showLoading();
            let res = {};
            if (this.cuItem.lesson !== null) {
                const arrLessons = this.selected.map((item) => item.lesson !== null && item.id).filter((item) => item);
                if (arrLessons.length > 0) {
                    res = await this.deleteMultiSubLessons(JSON.stringify(arrLessons));
                }
            } else {
                const arrTests = this.selected.map((item) => item.test !== null && item.id).filter((item) => item);
                if (arrTests.length > 0) {
                    res = await this.deleteMultiSubTests(JSON.stringify(arrTests));
                }
            }

            if (res?.isSuccess) {
                this.selected = [];
                await this.getQuestionToSection({
                    typeSection: this.cuItem.lesson !== null ? 'lesson' : 'test',
                    idSection: this.idSection,
                });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.data);
            }
            this.hideLoading();
        },
        cancelMulti() {
            this.isShowDialogMulti = false;
        },
        async deleteItemConfirm() {
            this.closeDelete();
            this.showLoading();

            let res = null;
            if (this.cuItem.lesson !== null) {
                res = await this.deleteOneLesson(this.cuItemDelete.id);
            } else {
                res = await this.deleteOneTest(this.cuItemDelete.id);
            }
            if (res?.isSuccess) {
                await this.getQuestionToSection({
                    typeSection: this.cuItem.lesson !== null ? 'lesson' : 'test',
                    idSection: this.idSection,
                });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.data);
            }

            this.hideLoading();
        },
        closeCU() {
            this.dialogCU = false;
            this.$nextTick(() => {
                this.cuItemQuestion = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItemQuestion = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeNoti() {
            this.dialogNoti = false;
        },
        async onSaveItem(callback) {
            let body = null;
            let res = null;

            this.showLoading();

            if (this.cuIndex === -1) {
                if (this.cuItem.lesson !== null) {
                    body = {
                        [this.cuItem.lesson !== null ? 'lesson_id' : 'test_id']: this.idSection,
                        title: this.experience.title,
                        text: this.experience.text,
                    };
                    res = await this.postNewLesson(body);
                } else {
                    body = {
                        data: {
                            [this.cuItem.lesson !== null ? 'lesson_id' : 'test_id']: this.idSection,
                            title: this.experience.title,
                            time_limit: this.experience.time_limit,
                            problem_statement: this.experience.problem_statement,
                            explanation: this.experience.explanation,
                            multiple_choice: this.experience.answers.filter((item) => item.correct !== false).length,
                        },
                        answers: this.experience.answers.map((item, index) => ({ ...item, order: index + 1 })),
                    };
                    res = await this.postNewTest(body);
                }
                callback(res?.isSuccess);
                if (res?.isSuccess) {
                    this.showSuccess(CRUD.CREATE);
                } else {
                    this.showError(res?.data);
                }
            } else {
                if (this.cuItem.lesson !== null) {
                    body = {
                        title: this.experience.title,
                        text: this.experience.text,
                    };
                    res = await this.putOneLesson({ id: this.cuItemQuestion.id, body: body });
                } else {
                    body = {
                        data: {
                            title: this.experience.title,
                            time_limit: this.experience.time_limit,
                            problem_statement: this.experience.problem_statement,
                            explanation: this.experience.explanation,
                            multiple_choice: this.experience.answers.filter((item) => item.correct !== false).length,
                        },
                        answers: this.experience.answers,
                    };
                    res = await this.putOneTest({ id: this.cuItemQuestion.id, body: body });
                }
                callback(res?.isSuccess);
                if (res?.isSuccess) {
                    this.showSuccess(CRUD.UPDATE);
                } else {
                    this.showError(res?.data);
                }
            }
            await this.getQuestionToSection({
                typeSection: this.cuItem.lesson !== null ? 'lesson' : 'test',
                idSection: this.idSection,
            });

            this.hideLoading();
        },
        setCurrentItem() {
            if (this.cuItem.lesson) {
                this.idSection = this.cuItem.lesson.id;
            } else if (this.cuItem.test) {
                this.idSection = this.cuItem.test.id;
            } else if (this.cuItem.form) {
                this.idSection = this.cuItem.form.id;
            }
        },

        //  ----------------------------- FUNCTION FORM QUESTIONS -----------------------------
        onAddFormQuestion() {
            this.dialogFormQuestion = true;
            this.dialogEdit = false;
        },

        onEditFormQuestion(item) {
            this.dialogFormQuestion = true;
            this.dialogEdit = true;
            this.cuIndex = this.items.indexOf(item);
            this.cuItemQuestion = Object.assign({}, item);
        },

        onDeleteFormQuestion(item) {
            this.dialogDelete = true;
            this.cuItemDelete = Object.assign({}, item);
        },

        onCloseFormQuestion() {
            this.dialogFormQuestion = false;
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.cuItemQuestion = {};
                this.cuIndex = -1;
            });
        },

        async onSaveFormQuestion(data, callback) {
            let body = {};
            let response = {};

            this.showLoading();

            if (this.cuIndex === -1) {
                body = {
                    ...data,
                    form_id: this.idSection,
                };
                response = await this.postFormQuestion(body);
            } else {
                body = {
                    ...data,
                    id: this.cuItemQuestion.id,
                };
                response = await this.putFormQuestion(body);
            }

            callback(response?.isSuccess);
            if (response?.isSuccess) {
                await this.getQuestionToSection({
                    typeSection: 'form',
                    idSection: this.idSection,
                });
                this.showSuccess(this.cuIndex === -1 ? CRUD.CREATE : CRUD.UPDATE);
            } else {
                this.showError(response?.message);
            }

            this.hideLoading();
        },

        async onConfirmDeleteFormQuestion() {
            this.closeDelete();
            this.showLoading();
            const response = await this.deleteFormQuestion(this.cuItemDelete.id);
            if (response?.isSuccess) {
                await this.getQuestionToSection({
                    typeSection: 'form',
                    idSection: this.idSection,
                });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(response?.message);
            }
            this.hideLoading();
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.cms-question__problem {
    background-color: transparent;

    & p {
        margin-bottom: 0;
        background-color: transparent !important;
    }
}
</style>
