var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cms-question"},[_c('a-row',{attrs:{"gutter":24,"type":"flex"}},[_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ padding: 0 }}},[[_c('v-data-table',{staticClass:"tableQuestion",attrs:{"headers":_vm.tableColumns,"items":_vm.items,"items-per-page":_vm.items.length,"loading":_vm.isLoadingQuestion || _vm.isLoadingFormQuestion,"show-select":"","hide-default-footer":"","loading-text":_vm.$t('cms.loading'),"no-data-text":_vm.$t('cms.no_data_available')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","dark":"","small":""},on:{"click":function($event){_vm.typeCollapse === 'form' ? _vm.onAddFormQuestion() : _vm.onAddNewItem()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s((_vm.typeCollapse === 'lesson' && _vm.$t('cms.add_sub_lesson')) || (_vm.typeCollapse === 'test' && _vm.$t('cms.add_question_test')) || (_vm.typeCollapse === 'form' && _vm.$t('cms.add_question_test')))+" ")],1),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length > 0),expression:"selected.length > 0"}],staticClass:"mr-2",attrs:{"color":"error","dark":"","small":""},on:{"click":_vm.onDeleteMulti}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v("  "+_vm._s(_vm.$t('form.delete'))+" ")],1)]}}]),model:{value:(_vm.dialogCU),callback:function ($$v) {_vm.dialogCU=$$v},expression:"dialogCU"}},[(_vm.dialogCU)?_c('QuestionModal',{attrs:{"cuItem":_vm.cuItemQuestion,"typeSection":_vm.typeSection,"formTitle":_vm.formTitle,"closeCU":_vm.closeCU,"save":_vm.onSaveItem,"propsField":_vm.propsField,"dialogEdit":_vm.dialogEdit,"experience":_vm.experience}}):_vm._e()],1),_c('v-dialog',{attrs:{"max-width":"1000px"},model:{value:(_vm.dialogFormQuestion),callback:function ($$v) {_vm.dialogFormQuestion=$$v},expression:"dialogFormQuestion"}},[_c('FormQuestionModal',{attrs:{"cuItem":_vm.cuItemQuestion,"onSave":_vm.onSaveFormQuestion,"onClose":_vm.onCloseFormQuestion,"dialogEdit":_vm.dialogEdit}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('ModalDelete',{attrs:{"closeDelete":_vm.closeDelete,"deleteItemConfirm":_vm.typeCollapse === 'form'
                                                ? _vm.onConfirmDeleteFormQuestion
                                                : _vm.deleteItemConfirm}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogNoti),callback:function ($$v) {_vm.dialogNoti=$$v},expression:"dialogNoti"}},[_c('ModalNoti',{attrs:{"close":_vm.closeNoti,"message":_vm.formMessage}})],1)],1)]},proxy:true},{key:"item.title",fn:function(ref){
                                                var item = ref.item;
return [_c('div',{staticClass:"my-2 line-clamp-2 text-break cms-question__problem"},[_c('MyEditor',{attrs:{"data":item.title || item.problem_statement || item.question_sentence,"isReadOnly":true}})],1)]}},{key:"item.created_at_unix_timestamp",fn:function(ref){
                                                var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.created_at_unix_timestamp)))])])]}},{key:"item.updated_at_unix_timestamp",fn:function(ref){
                                                var item = ref.item;
return [_c('div',{staticClass:"my-2"},[_c('span',[_vm._v(_vm._s(_vm.getFormatDate(+item.updated_at_unix_timestamp)))])])]}},{key:"item.actions",fn:function(ref){
                                                var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","small":""},on:{"click":function($event){_vm.typeCollapse === 'form' ? _vm.onEditFormQuestion(item) : _vm.onUpdateItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")]),_vm._v("  "+_vm._s(_vm.$t('form.edit'))+" ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","dark":"","small":""},on:{"click":function($event){_vm.typeCollapse === 'form' ? _vm.onDeleteFormQuestion(item) : _vm.onDeleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")]),_vm._v("  "+_vm._s(_vm.$t('form.delete'))+" ")],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})]],2)],1)],1),_c('f-notification-dialog',{staticClass:"dialogNoti",attrs:{"isShowDialog":_vm.isShowDialogMulti,"width":"500px","title":_vm.$t('cms.delete_multiple'),"content":_vm.$t('cms.confirm_delete_multiple'),"actions":_vm.actionsMulti}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }