<template>
    <div class="formQuestion__form">
        <v-card lazy-validation>
            <v-card-title class="cms_modal__title modal__title">
                <span class="text-h6">{{ dialogEdit ? $t('action.edit_question') : $t('action.new_question') }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <a-form :form="form">
                    <a-form-item>
                        <label class="formQuestion__label">{{ $t('cms.question_type') }}</label>
                        <a-select
                            v-decorator="[
                                'question_type',
                                {
                                    initialValue: cuItem.question_type || 'SINGLE_CHOICE_RADIO',
                                    rules: [{ required: true, message: $t('form.required') }],
                                },
                            ]"
                            @change="handleChangeType"
                        >
                            <a-select-option value="SINGLE_CHOICE_RADIO">
                                {{ $t('cms.SINGLE_CHOICE_RADIO') }}
                            </a-select-option>
                            <a-select-option value="MULTIPLE_CHOICE">
                                {{ $t('cms.MULTIPLE_CHOICE') }}
                            </a-select-option>
                            <a-select-option value="TEXT"> {{ $t('cms.TEXT') }} </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                        <a-checkbox :checked="isMandatory" @change="handleChangeMandatory">
                            {{ $t('cms.make_this_question_mandatory') }}
                        </a-checkbox>
                    </a-form-item>
                    <a-form-item>
                        <label class="formQuestion__label">{{ $t('cms.question_sentence') }}</label>
                        <div class="my-2">
                            <MyEditor :data.sync="questionSentence" />
                        </div>
                        <p
                            class="text-danger"
                            :style="{ lineHeight: 1 }"
                            v-if="!questionSentence && errorObject.questionSentence"
                        >
                            {{ errorObject.questionSentence }}
                        </p>
                    </a-form-item>
                    <a-form-item v-if="typeQuestion !== 'TEXT'" v-for="(item, index) in answers">
                        <div class="d-flex align-center justify-space-between">
                            <label class="formQuestion__label">{{ $t('form.answer') }}</label>
                            <a-button type="danger" size="small" @click="handleDeleteAnswer(index)">
                                {{ $t('form.delete') }}
                            </a-button>
                        </div>
                        <div class="my-2">
                            <MyEditor :data.sync="item.description" />
                        </div>
                        <p
                            class="text-danger"
                            :style="{ lineHeight: 1 }"
                            v-if="!item.description && errorObject.answer"
                        >
                            {{ errorObject.answer }}
                        </p>
                    </a-form-item>
                    <div class="text-center mb-5" v-if="typeQuestion !== 'TEXT'">
                        <a-button @click="handleAddAnswer">{{ $t('cms.add_answer') }}</a-button>
                    </div>
                </a-form>
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn color="blue darken-1" text @click="clickCancel">{{ $t('cancel') }}</v-btn>
                <v-btn color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import MyEditor from '../MyEditor';
import _ from 'lodash';

export default {
    components: {
        MyEditor,
    },
    props: {
        cuItem: { type: Object },
        onSave: { type: Function },
        onClose: { type: Function },
        dialogEdit: { type: Boolean },
    },
    data() {
        return {
            form: this.$form.createForm(this, { name: 'question-form' }),
            questionType: '',
            isMandatory: false,
            questionSentence: '',
            answers: [],
            errorObject: {
                questionSentence: '',
                answer: '',
            },
        };
    },
    created() {
        this.initialValues();
    },
    watch: {
        cuItem() {
            this.initialValues();
        },
    },
    methods: {
        initialValues() {
            if (this.dialogEdit) {
                this.typeQuestion = this.cuItem.question_type;
                this.isMandatory = this.cuItem.mandatory;
                this.questionSentence = this.cuItem.question_sentence;
                this.answers = _.sortBy(this.cuItem.form_answers, ['order']).map((item) => ({
                    id: item.id,
                    description: item.description,
                    order: item.order,
                }));
            }
        },

        handleChangeType(values) {
            this.typeQuestion = values;
            if (values === 'TEXT') {
                this.answers = [];
            }
        },

        handleChangeMandatory(e) {
            this.isMandatory = e.target.checked;
        },

        clickCancel() {
            this.onClose();
            this.form.resetFields();
            this.typeQuestion = '';
            this.questionSentence = '';
            this.isMandatory = false;
            this.answers = [];
            this.errorObject = {
                answer: '',
            };
        },

        clickSave(e) {
            e.preventDefault();
            let isValidEditor = true;

            if (!this.questionSentence) {
                this.errorObject.questionSentence = this.$t('form.required');
                isValidEditor = false;
            } else {
                this.errorObject.questionSentence = '';
            }

            if (this.answers.length > 0) {
                const checkAnswer = this.answers.some((item) => !item?.description?.trim());
                if (checkAnswer) {
                    this.errorObject.answer = this.$t('form.required');
                    isValidEditor = false;
                } else {
                    this.errorObject.answer = '';
                }
            }

            this.form.validateFields((err, values) => {
                if (!err && isValidEditor) {
                    const body = {
                        ...values,
                        mandatory: this.isMandatory,
                        question_sentence: this.questionSentence,
                        answers:
                            values.question_type === 'TEXT'
                                ? []
                                : this.answers.map((item) => ({ ...item, explanation: '' })),
                    };

                    this.onSave(body, (isSuccess) => {
                        if (isSuccess) this.clickCancel();
                    });
                }
            });
        },

        handleAddAnswer() {
            let order = this.answers.length + 1;

            if (this.dialogEdit) {
                if (this.answers.length === 0) {
                    order = 1;
                } else {
                    order = this.answers[this.answers.length - 1].order + 1;
                }
            } else {
                order = this.answers.length + 1;
            }

            this.answers.push({ id: '', description: '', order });
        },

        handleDeleteAnswer(index) {
            this.answers.splice(index, 1);
        },
    },
};
</script>

<style lang="scss">
.formQuestion__form {
    & .ant-form-item {
        margin-bottom: 24px;
    }
}

.formQuestion__label {
    display: block;
    margin-bottom: 5px;
    line-height: 1;
}

.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;
    margin-bottom: 10px;
    & .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }
    & .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
