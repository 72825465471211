<template>
    <v-form ref="form" @submit.prevent="clickSave">
        <v-card v-model="formHasErrors" lazy-validation>
            <v-card-title class="cms_modal__title">
                <span class="text-h6">{{ renderTitle() }}</span>
                <button type="button" class="btn-close" @click="clickCancel">
                    <v-icon class="fa-solid fa-xmark"></v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-row v-if="typeSection === 'Lesson'">
                        <v-col sm="12">
                            <v-text-field
                                :label="$t('form.title')"
                                v-model="experience.title"
                                ref="title"
                                :counter="255"
                                maxlength="255"
                                :rules="[requiredRule]"
                                required
                            ></v-text-field>
                        </v-col>
                        <v-col sm="12">
                            <v-card-title>本文</v-card-title>
                            <MyEditor :data.sync="experience.text" />
                            <span class="text-danger" v-if="!experience.text && errorObject.contentLesson">
                                {{ errorObject.contentLesson }}
                            </span>
                        </v-col>
                    </v-row>
                    <v-row v-if="typeSection === 'Test'">
                        <v-col sm="12">
                            <div>
                                <v-card-title>{{ $t('cms.problem_statement') }}</v-card-title>
                                <MyEditor :data.sync="experience.problem_statement" />
                                <span
                                    class="text-danger"
                                    v-if="!experience.problem_statement && errorObject.problem_statement"
                                >
                                    {{ errorObject.problem_statement }}
                                </span>
                            </div>
                            <div>
                                <v-card-title>{{ $t('cms.explanation') }}</v-card-title>
                                <MyEditor :data.sync="experience.explanation" />
                            </div>
                        </v-col>
                        <v-col sm="12">
                            <v-card-title>選択肢</v-card-title>
                            <v-row v-for="(item, index) in answers" :key="index">
                                <v-col sm="12">
                                    <v-col align-self="center">
                                        <v-row>
                                            <v-checkbox v-model="item.correct" class="m-auto" hide-details></v-checkbox>
                                            <v-card-subtitle text-subtitle-1>正解</v-card-subtitle>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                @click="delExperience(index)"
                                                type="button"
                                                class="btn btn-secondary m-auto"
                                                color="error"
                                            >
                                                削除
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                    <MyEditor :data.sync="item.description" />
                                    <span class="text-danger" v-if="!item.description && errorObject.contentAnswer">
                                        {{ errorObject.contentAnswer }}
                                    </span>
                                </v-col>
                                <v-col sm="12">
                                    <v-textarea
                                        auto-grow
                                        outlined
                                        rows="1"
                                        row-height="15"
                                        label="解説"
                                        v-model="item.explanation"
                                        ref="descriptionExperience"
                                    ></v-textarea>
                                </v-col>
                                <v-divider></v-divider>
                            </v-row>
                            <v-col sm="12" class="form-group text-center">
                                <v-btn @click="addExperience" type="button" class="btn btn-secondary">
                                    {{ $t('cms.add_experience') }}
                                </v-btn>
                            </v-col>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions class="cms_modal__actions justify-end">
                <v-btn type="button" color="blue darken-1" text @click="clickCancel">{{ $t('cancel') }} </v-btn>
                <v-btn type="button" color="blue darken-1" text @click="clickSave"> {{ $t('form.save') }} </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import { mixinRules, mixinToasts } from '../../../mixins';
import MyEditor from '../MyEditor';

export default {
    mixins: [mixinRules, mixinToasts],
    components: { MyEditor },
    props: {
        cuItem: {
            type: Object,
        },
        typeSection: {
            type: String,
        },
        formTitle: {
            type: String,
        },
        closeCU: { type: Function },
        save: { type: Function },
        propsField: { type: Array },
        dialogEdit: { type: Boolean },
        experience: { type: Object },
    },
    data() {
        return {
            selectTypeQuestion: null,
            errorMessages: '',
            formHasErrors: false,
            answers: [],
            errorObject: {
                problem_statement: '',
                contentLesson: '',
                contentAnswer: '',
                contentExplanAnswer: '',
            },
        };
    },
    computed: {
        form() {
            return {
                title: this.cuItem.title,
                text: this.cuItem.text,
                problem_statement: this.cuItem.problem_statement,
                explanation: this.cuItem.explanation,
            };
        },
        explanation: {
            get(val) {
                return val;
            },
            set(val) {
                if (val) {
                    return val;
                } else {
                    return 'abc';
                }
            },
        },
    },
    created() {
        this.checkQuestions(this.cuItem);
    },
    watch: {
        cuItem(val) {
            this.checkQuestions(val);
        },
    },
    methods: {
        renderTitle() {
            if (this.typeSection === 'Lesson') {
                return this.dialogEdit ? this.$t('action.edit_lesson') : this.$t('action.new_lesson');
            } else {
                return this.dialogEdit ? this.$t('action.edit_question') : this.$t('action.new_question');
            }
        },

        validate() {
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                if (!this.form[f]) this.formHasErrors = true;
                this.$refs[f].validate(true);
            });
        },

        resetForm() {
            this.errorMessages = [];
            this.formHasErrors = false;

            Object.keys(this.form).forEach((f) => {
                this.$refs.form.resetValidation();
            });
        },

        validateEditor() {
            let result = true;

            if (this.typeSection === 'Lesson') {
                if (!this.experience.text?.trim()) {
                    this.errorObject.contentLesson = this.$t('form.required');
                    result = false;
                } else {
                    this.errorObject.contentLesson = '';
                }
            } else {
                if (!this.experience.problem_statement?.trim()) {
                    this.errorObject.problem_statement = this.$t('form.required');
                    result = false;
                } else {
                    this.errorObject.problem_statement = '';
                }

                if (this.answers.length > 0) {
                    const checkAnswer = this.answers.some((item) => !item?.description?.trim());

                    if (checkAnswer) {
                        this.errorObject.contentAnswer = this.$t('form.required');
                        result = false;
                    } else {
                        this.errorObject.contentAnswer = '';
                    }
                }
            }

            return result;
        },

        clickSave() {
            let isValid = this.$refs.form.validate();
            let isValidEditor = this.validateEditor();

            if (!isValid || !isValidEditor) return;

            if (this.typeSection === 'Test') {
                const checkCorrectAnswer = this.answers?.filter((item) => item?.correct);
                if (checkCorrectAnswer.length === 0) {
                    isValid = false;
                } else {
                    this.experience.answers = this.answers;
                }
            }

            if (isValid) {
                this.save((isSuccess) => {
                    if (isSuccess) this.clickCancel();
                });
            } else {
                this.showToast('warning', this.$t('cms.please_choose_one_correct_answer'));
            }
        },

        clickCancel() {
            this.closeCU();
            this.resetForm();
            this.answers = [];
            this.errorObject = {
                problem_statement: '',
                contentLesson: '',
                contentAnswer: '',
                contentExplanAnswer: '',
            };
        },

        checkQuestions(item) {
            if (this.typeSection === 'Lesson') {
                this.experience.type = 'Lesson';
                this.experience.title = this.cuItem?.title;
                this.experience.text = this.cuItem?.text;
            }
            if (this.typeSection === 'Test') {
                this.experience.type = 'Test';
                this.experience.problem_statement = this.cuItem?.problem_statement;
                this.experience.explanation = this.cuItem?.explanation || '';
                this.answers = _.sortBy(this.cuItem?.test_answers, ['order']).map((item) => ({
                    id: item.id,
                    description: item.description,
                    explanation: item.explanation,
                    correct: item.correct,
                    order: item.order,
                }));
            }
        },

        addExperience() {
            let order = 0;

            if (this.dialogEdit) {
                if (this.answers.length === 0) {
                    order = this.cuItem.test_answers[this.cuItem.test_answers.length - 1].order + 1;
                } else {
                    order = this.answers[this.answers.length - 1].order + 1;
                }
            } else {
                order = this.answers.length + 1;
            }

            this.answers &&
                this.answers.push({
                    id: '',
                    description: '',
                    explanation: '',
                    correct: false,
                    order,
                });
        },

        delExperience(indexExper) {
            this.answers = this.answers.filter((item, index) => index !== indexExper);
        },
    },
};
</script>

<style lang="scss">
.ck.ck-editor {
    width: 100%;
}

.modal__title {
    display: flex;
    justify-content: space-between;
    line-height: 1;

    .btn-close i {
        color: #aaa !important;
        transition: all 0.4s ease;
    }

    .btn-close:hover i {
        color: #000 !important;
    }
}
</style>
