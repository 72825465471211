<template>
    <div>
        <a-row :gutter="24" type="flex">
            <a-col :span="24" class="mb-24">
                <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
                    <template #title>
                        <a-row type="flex" align="middle">
                            <a-col :span="24" :md="12">
                                <h5 class="font-semibold m-0">{{ $t('table_title.Section') }}</h5>
                            </a-col>
                        </a-row>
                    </template>
                    <template>
                        <v-data-table
                            :headers="hiddenColTable"
                            :items="sections"
                            class="tableSection"
                            show-select
                            v-model="selected"
                            hide-default-footer
                            :loading="isLoadingSection"
                            :loading-text="$t('cms.loading')"
                            :page="page"
                            :single-expand="true"
                            :expanded.sync="expanded"
                            show-expand
                            :no-data-text="$t('cms.no_data_available')"
                        >
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-dialog v-model="dialogCU" persistent max-width="1000px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark class="mr-2" @click="onAddNewItem()" small>
                                                <v-icon small> mdi-plus </v-icon>
                                                {{ $t('cms.add_new') }}
                                            </v-btn>
                                            <v-btn
                                                color="error"
                                                dark
                                                class="mr-2"
                                                small
                                                v-show="selected.length > 0"
                                                @click="onDeleteMulti"
                                            >
                                                <v-icon small> mdi-delete </v-icon>
                                                &nbsp;{{ $t('form.delete') }}
                                            </v-btn>
                                        </template>

                                        <SectionModal
                                            :cuItem="cuItem"
                                            :formTitle="formTitle"
                                            :closeCU="closeCU"
                                            :save="onSaveItem"
                                            :propsField="propsField"
                                            :columns="tableColumns"
                                            :dialogEdit="dialogEdit"
                                            :typeSection="typeSection"
                                            :editorDataLesson="editorDataLesson"
                                            :editorDataTest="editorDataTest"
                                            :editorDataForm="editorDataForm"
                                            :openModalUploadQuestion="openModalUploadQuestion"
                                            v-if="dialogCU"
                                        >
                                        </SectionModal>
                                    </v-dialog>

                                    <v-spacer></v-spacer>

                                    <div class="search">
                                        <a-select :default-value="selectedField" @change="handleChangeSelect">
                                            <a-select-option
                                                v-for="(item, index) in fieldsSearch"
                                                :key="index"
                                                :value="item.value"
                                            >
                                                {{ item.title }}
                                            </a-select-option>
                                        </a-select>
                                        <a-input-search
                                            v-model="search"
                                            :placeholder="$t('header.search')"
                                            @change="onSearchItem"
                                        />
                                    </div>

                                    <v-dialog v-model="dialogDelete" max-width="500px">
                                        <ModalDelete :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm">
                                        </ModalDelete>
                                    </v-dialog>

                                    <v-dialog v-model="dialogNoti" max-width="500px">
                                        <ModalNoti :close="closeNoti" :message="formMessage"></ModalNoti>
                                    </v-dialog>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.title="{ item }">
                                <div class="line-clamp-2">
                                    <span>
                                        {{
                                            (item.test && item.test.title) ||
                                            (item.lesson && item.lesson.title) ||
                                            (item.form && item.form.title)
                                        }}
                                    </span>
                                </div>
                            </template>
                            <template v-slot:item.type="{ item }">
                                <span>
                                    {{
                                        (item.test && $t('cms.test')) ||
                                        (item.lesson && $t('cms.lesson')) ||
                                        (item.form && $t('cms.form'))
                                    }}
                                </span>
                            </template>
                            <template v-slot:[`item.created_at_unix_timestamp`]="{ item }">
                                <div class="my-2">
                                    <span>{{ getFormatDate(+item.created_at_unix_timestamp) }}</span>
                                </div>
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn color="info" class="mr-2" small @click="onUpdateItem(item)">
                                    <v-icon small> mdi-pencil </v-icon>
                                    &nbsp;{{ $t('form.edit') }}
                                </v-btn>
                                <v-btn color="error" dark class="mr-2" small @click="onDeleteItem(item)">
                                    <v-icon small> mdi-delete </v-icon>
                                    &nbsp;{{ $t('form.delete') }}
                                </v-btn>
                            </template>
                            <template v-slot:expanded-item="{ headers, item }">
                                <td :colspan="headers.length" class="section__child-table">
                                    <QuestionCollapse
                                        :typeCollapse="typeCollapse"
                                        :items="typeCollapse === 'form' ? formQuestionList : questions"
                                        :cuItem="item"
                                        :getQuestionToSection="getQuestionToSection"
                                    ></QuestionCollapse>
                                </td>
                            </template>
                        </v-data-table>
                        <div class="text-center pt-2">
                            <f-pagination
                                v-model="page"
                                :pageSize="sectionsPagination.pageSize"
                                :totalItems="sectionsPagination.totalItems"
                                :disabled="isLoadingSection"
                            />
                        </div>
                    </template>
                </a-card>
            </a-col>
        </a-row>
        <f-notification-dialog
            class="dialogNoti"
            :isShowDialog="isShowDialogMulti"
            width="500px"
            :title="$t('cms.delete_multiple')"
            :content="$t('cms.confirm_delete_multiple')"
            :actions="actionsMulti"
        />

        <!-- MODAL UPLOAD QUESTIONS -->
        <v-dialog v-model="dialogUploadQuestion" persistent max-width="1000px">
            <ModalUploadQuestion :onClose="closeModalUploadQuestion" />
        </v-dialog>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import SectionModal from '../../components/CMS/Modal/Section.vue';
import ModalDelete from '../../components/CMS/Modal/Delete.vue';
import ModalNoti from '../../components/CMS//Modal/Noti.vue';
import ModalUploadQuestion from '../../components/CMS/Modal/UploadQuestion.vue';
import QuestionCollapse from '../../views/CMS/Question.vue';
import { CRUD } from '../../constants';
import { mixinToasts, mixinFormats, mixinLoadings } from '../../mixins';
import * as _ from 'lodash';

export default {
    mixins: [mixinToasts, mixinFormats, mixinLoadings],
    components: { SectionModal, ModalDelete, ModalNoti, QuestionCollapse, ModalUploadQuestion },

    data() {
        return {
            tableColumns: [
                { text: this.$t('form.title'), value: 'title', sortable: false, width: '200px' },
                { text: this.$t('form.type'), value: 'type', sortable: false, width: '175px' },
                {
                    text: this.$t('form.created_at'),
                    value: 'created_at_unix_timestamp',
                    sortable: false,
                    width: '175px',
                },
                { text: this.$t('cms.action'), value: 'actions', sortable: false, width: '210px' },
                { text: '', value: 'data-table-expand', width: '100px' },
            ],
            fieldsSearch: [
                {
                    title: this.$t('form.title'),
                    value: 'title',
                },
            ],
            selectedField: 'title',
            idRoute: '',
            typeSection: {
                type: '',
                title: '',
            },
            editorDataLesson: {
                page_title: '',
                progress_control: false,
                finish_condition: 'SEEN',
                text: '',
            },
            editorDataTest: {
                pass_score: 100,
                time_limit: null,
                shuffle: false,
                problem_statement: '',
                explanation: '',
            },
            editorDataForm: {
                retake: false,
            },
            expanded: [],
            search: '',
            page: 1,
            cuIndex: -1,
            cuItem: {},
            cuItemDelete: {},
            dialogCU: false,
            dialogDelete: false,
            dialogNoti: false,
            dialogEdit: false,
            dialogUploadQuestion: false,
            isShowDialogMulti: false,
            actionsMulti: [],
            selected: [],
            noImage: require('../../assets/images/no_image.jpg'),
            formMessage: '',
            typeCollapse: '',
        };
    },
    async created() {
        this.hiddenFieldModal(this.dialogEdit);
    },
    computed: {
        ...mapState('question', ['isLoadingQuestion', 'questions', 'questionsPagination']),
        ...mapState('section', ['isLoadingSection', 'sections', 'sectionsPagination']),
        ...mapState('formQuestion', ['formQuestionList']),
        formTitle() {
            return this.cuIndex === -1 ? this.$t('cms.new_item') : this.$t('cms.edit_item');
        },
        hiddenColTable() {
            return this.tableColumns.filter((s) => s.value !== 'password');
        },
    },
    async mounted() {
        const getIdChapterRoute = this.$router?.history?.current?.query?.chapter_id;
        this.idRoute = getIdChapterRoute;
        await this.getSectionList({
            page: this.page,
            field: this.selectedField,
            search: this.search,
            chapter_id: this.idRoute,
        });
    },
    watch: {
        page(val) {
            this.getSectionList({
                page: val,
                field: this.selectedField,
                search: this.search,
                chapter_id: this.idRoute,
            });
        },
        dialogCU(val) {
            val || this.closeCU();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        dialogNoti(val) {
            val || this.closeNoti();
        },
        dialogEdit(val) {
            this.hiddenFieldModal(val);
        },
        expanded(val) {
            if (val.length) {
                const item = val[0];
                let body = {
                    typeSection: '',
                    idSection: '',
                };

                if (item.lesson) {
                    this.typeCollapse = 'lesson';
                    body.typeSection = 'lesson';
                    body.idSection = item.lesson.id;
                } else if (item.test) {
                    this.typeCollapse = 'test';
                    body.typeSection = 'test';
                    body.idSection = item.test.id;
                } else if (item.form) {
                    this.typeCollapse = 'form';
                    body.typeSection = 'form';
                    body.idSection = item.form.id;
                }

                this.getQuestionToSection(body);
            } else {
                this.typeCollapse = '';
            }
        },
    },
    methods: {
        ...mapActions('question', ['getQuestions']),
        ...mapActions('formQuestion', ['getFormQuestions']),
        ...mapActions('section', [
            'getSections',
            'postNewLesson',
            'postNewTest',
            'postNewForm',
            'putOneLesson',
            'putOneTest',
            'putOneForm',
            'deleteOneLesson',
            'deleteOneTest',
            'deleteOneForm',
            'deleteMultiSections',
        ]),
        async getSectionList(data) {
            this.page = data?.page || 1;
            this.selectedField = data?.field;
            this.search = data?.search || '';
            this.idRoute = data?.chapter_id;
            await this.getSections(data);
        },
        hiddenFieldModal(val) {
            if (val === true) {
                this.propsField = this.tableColumns.filter(
                    (s) => s.value !== 'password' && s.value !== 'created_at' && s.value !== 'updated_at',
                );
            } else {
                this.propsField = this.tableColumns.filter((s) => s.value !== 'created_at' && s.value !== 'updated_at');
            }
        },
        onAddNewItem() {
            this.dialogEdit = false;
            this.dialogCU = true;
        },
        onUpdateItem(item) {
            this.dialogEdit = true;
            this.dialogCU = true;
            this.cuIndex = this.sections.indexOf(item.id);
            this.cuItem = Object.assign({}, item);
        },
        onDeleteItem(item) {
            this.dialogDelete = true;
            this.cuIndex = this.sections.indexOf(item);
            this.cuItemDelete = Object.assign({}, item);
        },
        onDeleteMulti() {
            this.isShowDialogMulti = true;
            this.actionsMulti = [
                {
                    title: this.$t('cancel'),
                    function: this.cancelMulti,
                },
                {
                    title: this.$t('yes'),
                    function: this.agreeMulti,
                },
            ];
        },
        async agreeMulti() {
            this.isShowDialogMulti = false;
            this.showLoading();
            const arr = this.selected.map((item) => item.id);
            if (arr.length > 0) {
                const res = await this.deleteMultiSections(JSON.stringify(arr));
                if (res?.isSuccess) {
                    this.selected = [];
                    await this.getSectionList({
                        page: this.page,
                        field: this.selectedField,
                        search: this.search,
                        chapter_id: this.idRoute,
                    });
                    this.showSuccess(CRUD.DELETE);
                } else {
                    this.showError(res?.data);
                }
            }
            this.hideLoading();
        },
        cancelMulti() {
            this.isShowDialogMulti = false;
        },
        async deleteItemConfirm() {
            this.closeDelete();
            this.showLoading();
            let res = null;
            if (this.cuItemDelete.lesson) {
                res = await this.deleteOneLesson(this.cuItemDelete.lesson.id);
            } else if (this.cuItemDelete.test) {
                res = await this.deleteOneTest(this.cuItemDelete.test.id);
            } else if (this.cuItemDelete.form) {
                res = await this.deleteOneForm(this.cuItemDelete.form.id);
            }
            if (res?.isSuccess) {
                await this.getSectionList({
                    page: this.page,
                    field: this.selectedField,
                    search: this.search,
                    chapter_id: this.idRoute,
                });
                this.showSuccess(CRUD.DELETE);
            } else {
                this.showError(res?.data);
            }
            this.hideLoading();
        },
        closeCU() {
            this.dialogCU = false;
            this.dialogEdit = false;
            this.$nextTick(() => {
                this.cuItem = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.cuItemDelete = Object.assign({}, this.defaultItem);
                this.cuIndex = -1;
            });
        },
        closeNoti() {
            this.dialogNoti = false;
        },
        async onSaveItem(callback) {
            this.showLoading();
            let res = null;
            if (this.dialogEdit === false) {
                const getIDRoute = this.$router?.history?.current?.query?.chapter_id;
                let body = {
                    chapter_id: getIDRoute,
                    ...this.typeSection,
                };
                if (this.typeSection.type === 'Lesson') {
                    body = {
                        ...body,
                        ...this.editorDataLesson,
                    };
                    res = await this.postNewLesson(body);
                } else if (this.typeSection.type === 'Test') {
                    body = {
                        ...body,
                        ...this.editorDataTest,
                        time_limit: this.editorDataTest?.time_limit || null,
                        pass_score: parseInt(this.editorDataTest?.pass_score),
                    };
                    res = await this.postNewTest(body);
                } else if (this.typeSection.type === 'Form') {
                    body = {
                        ...body,
                        ...this.editorDataForm,
                    };
                    res = await this.postNewForm(body);
                }
                callback(res?.isSuccess);
                if (res?.isSuccess) {
                    await this.getSectionList({ page: 1, field: 'title', search: '', chapter_id: this.idRoute });
                    this.showSuccess(CRUD.CREATE);
                } else {
                    this.showError(res?.data);
                }
            } else {
                if (this.cuItem.lesson !== null) {
                    res = await this.putOneLesson({
                        id: this.cuItem.lesson.id,
                        ...this.typeSection,
                        ...this.editorDataLesson,
                    });
                } else if (this.cuItem.test !== null) {
                    res = await this.putOneTest({
                        id: this.cuItem.test.id,
                        ...this.typeSection,
                        ...this.editorDataTest,
                        time_limit: this.editorDataTest?.time_limit || null,
                        pass_score: parseInt(this.editorDataTest?.pass_score),
                    });
                } else if (this.cuItem.form !== null) {
                    res = await this.putOneForm({
                        id: this.cuItem.form.id,
                        ...this.typeSection,
                        ...this.editorDataForm,
                    });
                }
                callback(res?.isSuccess);
                if (res?.isSuccess) {
                    await this.getSectionList({
                        page: this.page,
                        field: this.selectedField,
                        search: this.search,
                        chapter_id: this.idRoute,
                    });
                    this.showSuccess(CRUD.UPDATE);
                } else {
                    this.showError(res?.data);
                }
            }
            this.hideLoading();
        },
        onSearchItem: _.debounce(function (e) {
            this.getSectionList({ page: 1, field: this.selectedField, search: this.search, chapter_id: this.idRoute });
        }, 500),
        handleChangeSelect(value) {
            this.getSectionList({ page: 1, field: value, search: this.search, chapter_id: this.idRoute });
        },
        async getQuestionToSection(item) {
            if (item.typeSection === 'form') {
                await this.getFormQuestions({
                    form_id: item.idSection,
                });
            } else {
                await this.getQuestions({
                    page: this.page,
                    field: this.selectedField,
                    search: this.search,
                    getParams: item.typeSection,
                    getParamsID: item.idSection,
                });
            }
        },

        // FUNCTION UPLOAD QUESTIONS
        openModalUploadQuestion() {
            this.dialogUploadQuestion = true;
        },

        closeModalUploadQuestion(action) {
            this.dialogUploadQuestion = false;
            if (action === 'UPLOAD_SUCCESS') {
                this.closeCU();
                this.getSectionList({ page: 1, field: 'title', search: '', chapter_id: this.idRoute });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    & .ant-select {
        margin-right: 10px;
        width: 120px;
    }

    & .ant-input-search {
        width: 500px;
    }
}

.section__child-table {
    padding: 20px !important;
}
</style>
