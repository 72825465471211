<template lang="">
    <div class="updateQuestion">
        <div class="updateQuestion__top">
            <h6>{{ $t('cms.upload_question') }}</h6>
            <button type="button" class="btn-close ml-5" @click="onClose('NOT_UPLOAD')">
                <v-icon class="fa-solid fa-xmark"></v-icon>
            </button>
        </div>
        <div class="updateQuestion__content">
            <a-collapse v-model="activeKey">
                <a-collapse-panel key="1" :header="$t('cms.step_1_download_file')">
                    <p class="updateQuestion__download" @click="handleDownTemplate">
                        <span class="mdi mdi-file-document mr-2"></span>{{ $t('cms.download_template') }}
                    </p>
                </a-collapse-panel>
                <a-collapse-panel key="2" :header="$t('cms.step_2_edit_content')">
                    <p class="content-text" v-if="currentLanguage === 'en'">
                        Write one section or one problem per line. When adding a section, write only the section name in
                        the first column. If you add an issue, describe it in the 2nd column and beyond.
                        <br/>
                        Correct answers start with "#"
                        <br /><br />
                        Column A: Title Section<br />
                        Column B: Question<br />
                        Column C: Comment for question<br />
                        Column D: Answer A<br />
                        Column E: Comment for answer A<br />
                        Column F: Answer B<br />
                        Column G: Comment for answer B<br />
                        …<br />
                        Column K: Comment for answer D
                    </p>
                    <p class="content-text" v-else>
                        1行に1つのセクションまたは1つの問題を書きます。セクションを追加する場合
                        は、1列目にセクション名だけを書きます。課題を追加した場合 を2段目以降に記述してください。
                        <br />
                        正解には「#」から始めます。
                        <br /><br />
                        A列：セクション名<br />
                        B列： 問題<br />
                        C列： 問題の解説<br />
                        D列： 選択肢A<br />
                        E列： 選択肢Aの解説<br />
                        F列： 選択肢B<br />
                        G列：選択肢Bの解説<br />
                        ...<br />
                        K列：選択肢Dの解説<br />
                    </p>
                </a-collapse-panel>
                <a-collapse-panel key="3" :header="$t('cms.step_3_upload_file')">
                    <p>{{ $t('cms.only_accept_file') }}</p>
                    <input ref="fileRef" type="file" accept=".xlsx, .xls" @change="onChangeFile" />
                </a-collapse-panel>
                <Confirm :isOpen="isOpenModal" :onClose="onCloseConfirm" :onConfirm="onConfirm" />
            </a-collapse>
        </div>
    </div>
</template>

<script>
import Confirm from './Confirm.vue';
import { cmsUploadExcelApi } from '../../../api';
import { mixinToasts, mixinLoadings } from '../../../mixins';

export default {
    mixins: [mixinToasts, mixinLoadings],
    components: {
        Confirm,
    },
    props: {
        onClose: { type: Function },
    },
    data() {
        return {
            activeKey: ['1', '2', '3'],
            isOpenModal: false,
            filePath: null,
            currentLanguage: this.$store.getters.getLang,
        };
    },
    methods: {
        async handleDownTemplate() {
            this.showLoading();
            try {
                const res = await cmsUploadExcelApi.downloadTemplateTest();
                const { url } = res.results.object;

                if (url) {
                    let fileLink = document.createElement('a');
                    fileLink.href = url;
                    fileLink.setAttribute('download', 'file.xlsx');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            } catch (error) {
                this.showError(error);
            }
            this.hideLoading();
        },

        onChangeFile(e) {
            const file = e.target.files[0];
            if (file) {
                this.filePath = file;
                this.isOpenModal = true;
            }
        },

        async onConfirm() {
            this.onCloseConfirm();

            this.showLoading();
            try {
                const body = new FormData();
                body.append('file', this.filePath);
                body.append('chapter_id', this.$route.query.chapter_id);
                await cmsUploadExcelApi.uploadTest(body);
                this.onClose('UPLOAD_SUCCESS');
            } catch (error) {
                this.showError(error);
            }
            this.hideLoading();
        },

        onCloseConfirm() {
            this.isOpenModal = false;
            this.$refs.fileRef.value = '';
        },
    },
};
</script>

<style lang="scss">
.updateQuestion {
    background-color: #fff;
    padding: 30px 20px;
}

.updateQuestion__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & h6 {
        margin-bottom: 0;
        font-size: 20px;
    }
}

.updateQuestion__content {
    margin-top: 20px;
}

.updateQuestion__download {
    margin-bottom: 0 !important;
    color: cornflowerblue;
    cursor: pointer;
}
</style>
